import { ArrowRightIcon } from 'fitify-ui/src/Icon/outline'
import { LinkTypes } from 'fitify-ui-landing/src/@types'

const ONBOARDING_URL = process.env.NEXT_PUBLIC_ONBOARDING_URL

const navigationData: LinkTypes.Navigation[] = [
  {
    link: ONBOARDING_URL + '/gift',
    type: 'link',
    caption: 'landing_buy_gift',
    link_type: '_blank',
    isHiddenOnMobile: true,
    isOnboarding: true,
  },
  {
    link: ONBOARDING_URL,
    type: 'button',
    caption: 'landing_cta_button',
    icon: <ArrowRightIcon />,
    isHiddenOnMobile: false,
    isOnboarding: true,
  },
]

const businessNavigationData: LinkTypes.Navigation[] = [
  {
    link: '/',
    type: 'link',
    caption: 'landing_for_individuals',
    isHiddenOnMobile: false,
  },
  {
    link: '/business',
    type: 'link',
    caption: 'landing_work_section_title_for_business',
    isHiddenOnMobile: false,
  },
  {
    link: '#contact',
    type: 'button',
    caption: 'landing_contact_us',
    icon: <ArrowRightIcon />,
    link_type: '_self',
    isHiddenOnMobile: false,
  },
]

export { navigationData, businessNavigationData }
