type ColorsDefinition = Record<string, string>

export const colors = {
  Black: '#000000',
  White: '#FFFFFF',

  Green0: '#F3FFE4',
  Green50: '#E6FFC8',
  Green100: '#DBFF9F',
  Green200: '#C8FF71',
  Green300: '#B8FA4E',
  Green400: '#95EB00', // base color
  Green500: '#80DB00',
  Green600: '#68B800',
  Green700: '#519300',

  Yellow50: '#FFFBDF',
  Yellow200: '#FFE460',
  Yellow300: '#FFD720',
  Yellow400: '#FFCC00', // base color
  Yellow500: '#F4BB00',
  Yellow600: '#D19600',
  Yellow700: '#A97600',
  Yellow900: '#462600',

  Orange50: '#FFEFDF',
  Orange200: '#FFAE60',
  Orange300: '#FF8D20',
  Orange400: '#FF7D00', // base color
  Orange500: '#EF7500',
  Orange700: '#9F4E00',

  Gray50: '#FAFAFA',
  Gray100: '#F5F5F5',
  Gray200: '#EEEEEE',
  Gray300: '#E0E0E0',
  Gray400: '#BDBDBD', // base color
  Gray500: '#9E9E9E',
  Gray600: '#757575',
  Gray700: '#616161',
  Gray800: '#424242',
  Gray900: '#212121',

  Red0: '#FFF3EF',
  Red50: '#FFE7DF',
  Red100: '#FFB69F',
  Red200: '#FF8660',
  Red300: '#FF5520',
  Red400: '#FF3D00',
  Red500: '#EF3900',
  Red600: '#C73000',

  Blue0: '#EFF7FF',
  Blue50: '#DFEEFF',
  Blue100: '#9FCDFF',
  Blue200: '#60ACFF',
  Blue400: '#007AFF',
  Blue500: '#026EFF',
  Blue600: '#015ADC',
  Blue700: '#0045B9',
  Blue850: '#0c3d7d',
  Blue900: '#001538',

  Purple50: '#FADEFF',
  Purple200: '#EA80FC',
  Purple400: '#D500F9',
  Purple600: '#9203BC',

  Teal50: '#DBFFFF',
  Teal400: '#00DBDB',

  Mint50: '#DDFFF7',
  Mint400: '#00EEB9',
  Mint500: '#00DFAE',
  INHERIT: 'inherit',
  TRANSPARENT: 'transparent',
} as const satisfies ColorsDefinition

export type ThemeColors = keyof typeof colors

export const addAlpha = (color: string, opacity: number): string => {
  // Clamp the opacity between 0 and 1
  const clampedOpacity = Math.min(Math.max(opacity || 0, 0), 1)

  // Convert the opacity to a 0-255 range and then to a hex string
  const opacityHex = Math.round(clampedOpacity * 255).toString(16)
  const paddedHex = opacityHex.padStart(2, '0').toUpperCase()

  return color + paddedHex
}
